<script>
export default {
  name: "i-variant"
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10305_14529)">
      <path
          d="M7.1998 9.2998H2.7998C1.97138 9.2998 1.2998 9.97138 1.2998 10.7998V20.1998C1.2998 21.0282 1.97138 21.6998 2.7998 21.6998H7.1998C8.02823 21.6998 8.6998 21.0282 8.6998 20.1998V10.7998C8.6998 9.97138 8.02823 9.2998 7.1998 9.2998Z"
          fill="currentColor"/>
      <path
          d="M21.1998 21.6998H16.7998C15.9698 21.6998 15.2998 21.0298 15.2998 20.1998V10.7998C15.2998 9.9698 15.9698 9.2998 16.7998 9.2998H21.1998C22.0298 9.2998 22.6998 9.9698 22.6998 10.7998V20.1998C22.6998 21.0298 22.0298 21.6998 21.1998 21.6998ZM16.7998 10.6998C16.7498 10.6998 16.6998 10.7398 16.6998 10.7998V20.1998C16.6998 20.2498 16.7398 20.2998 16.7998 20.2998H21.1998C21.2498 20.2998 21.2998 20.2598 21.2998 20.1998V10.7998C21.2998 10.7398 21.2598 10.6998 21.1998 10.6998H16.7998Z"
          fill="currentColor"/>
      <path
          d="M12 11.25C11.59 11.25 11.25 10.91 11.25 10.5V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V10.5C12.75 10.91 12.41 11.25 12 11.25Z"
          fill="currentColor"/>
      <path
          d="M12 15.25C11.59 15.25 11.25 14.91 11.25 14.5V13C11.25 12.59 11.59 12.25 12 12.25C12.41 12.25 12.75 12.59 12.75 13V14.5C12.75 14.91 12.41 15.25 12 15.25Z"
          fill="currentColor"/>
      <path
          d="M12 19.25C11.59 19.25 11.25 18.91 11.25 18.5V17C11.25 16.59 11.59 16.25 12 16.25C12.41 16.25 12.75 16.59 12.75 17V18.5C12.75 18.91 12.41 19.25 12 19.25Z"
          fill="currentColor"/>
      <path
          d="M12 23.25C11.59 23.25 11.25 22.91 11.25 22.5V21C11.25 20.59 11.59 20.25 12 20.25C12.41 20.25 12.75 20.59 12.75 21V22.5C12.75 22.91 12.41 23.25 12 23.25Z"
          fill="currentColor"/>
      <path
          d="M5.91937 6.39985C6.41937 6.23985 6.92937 6.07985 7.42937 5.92985C7.80937 5.81985 8.10937 5.99985 8.19937 6.37985C8.25937 6.62985 8.14937 6.85985 7.92937 6.97985C7.82937 7.02985 7.72937 7.06985 7.61937 7.09985C6.76937 7.37985 5.92937 7.64985 5.07937 7.91985C4.68937 8.04985 4.34937 7.88985 4.22937 7.49985C3.93937 6.60985 3.65938 5.71985 3.38937 4.81985C3.34938 4.67985 3.34938 4.49985 3.38937 4.35985C3.46937 4.09985 3.66937 3.94985 3.94937 3.95985C4.22937 3.95985 4.40937 4.12985 4.49937 4.38985C4.62937 4.75985 4.73937 5.13985 4.87937 5.57985C5.08938 5.24985 5.26937 4.96985 5.45937 4.69985C6.96937 2.54985 9.03938 1.34985 11.6594 1.12985C13.2194 0.999851 14.7094 1.31985 16.0894 2.04985C18.0694 3.09985 19.4094 4.70985 20.1194 6.83985C20.2594 7.26985 20.0194 7.62985 19.5794 7.64985C19.3094 7.65985 19.1394 7.51985 19.0294 7.27985C18.8094 6.76985 18.6194 6.22985 18.3294 5.75985C17.0194 3.63985 15.1094 2.46985 12.5994 2.30985C11.2194 2.21985 9.94938 2.54985 8.75938 3.21985C7.55937 3.88985 6.65938 4.84985 6.00938 6.04985C5.95938 6.13985 5.91937 6.24985 5.87937 6.33985C5.89937 6.35985 5.90937 6.37985 5.92937 6.39985H5.91937Z"
          fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_10305_14529">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped>

</style>
