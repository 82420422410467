<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import ISearch from "@/assets/img/icon/i-search.vue";
import {useAuthStore} from "@/store/auth";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import IChevron from "@/assets/img/icon/i-chevron.vue";

const searchText = ref('');
const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});

// Define emit
const emit = defineEmits(['setModalData']);
const authStore = useAuthStore();
const store = useStore();
const router = useRouter();

// Pagenation Set
const selectedPage = ref(1);
const totalPage = computed(() => Math.ceil(store.userList.length / 10));
const pageNationArr = ref([]);

function setPageNation() {
  let temp = [];
  const firstPage = 10 * (selectedPage.value - 1) + 1;
  const lastPage = 10 * selectedPage.value;

  for (let i = 1; i <= totalPage.value; i++) {
    if (i >= firstPage && i <= lastPage) {
      temp.push(i);
    }

    if (temp.length === 10) {
      break;
    }
  }

  pageNationArr.value = temp;
}

function movePageByDirection(dir) { // false: prev, true: next
  if (!dir) {
    if (selectedPage.value - 1 === 0) {
      return;
    }

    selectedPage.value -= 1;

  }

  if(dir) {
    if (selectedPage.value === totalPage.value) {
      return;
    }
    selectedPage.value += 1;
  }
}

// emit for setting modal data
function setModalData(action, name, role, username, email, uniqueId, password='') {
  const payload = {
    modalType: 'user',
    action: action,
    name: username,
    role: role,
    userId: name,
    email: email,
    uniqueId: uniqueId,
    password: password
  }
  emit('setModalData', payload);
}

onMounted(async () => {
  if (!authStore.userId) { // JWT 만료시 로그인 페이지 ㄱㄱ
    alert(`You are not authenticated. Redirect to the login page.`);
    await router.push({name: 'Login'});
  }

  if (store.userList.length === 0) {
    await store.getUserList();
  }

  setPageNation();
})

onUnmounted(async () => {
  await store.getUserList();
})
</script>
<template>
  <div id="userSetting">
    <div class="top">
      <h3>사용자 관리</h3>
      <button class="btn size_24 primary" @click="setModalData(false)">사용자 추가</button>
    </div>

    <div class="search_container">
      <input type="text" placeholder="검색어를 입력하세요." v-model="searchText" @keydown.enter="store.getUserList(searchText)"/>
      <i-search class="icon size_20 blue"/>
    </div>

    <span class="total_count">{{ store.userList.length }}개의 사용자</span>

    <div v-show="(index+1) >= 10*(selectedPage-1)+1 && (index+1) <= 10*selectedPage"
         v-for="(ul, index) in store.userList" class="table_row" @click="setModalData(true, ul.name, ul.role, ul.nickname, ul.email, ul.id)">
      <div class="index">{{ store.modifyIndex(index + 1) }}</div>
      <div class="title">
        <h6>{{ ul.name }}</h6>
        <span>{{ ul.nickname }}</span>
      </div>
      <span v-if="ul.role === 'ADMIN'" class="permission_badge">관리자</span>
    </div>

    <div v-if="pageNationArr.length > 1" class="page_nation" style="margin-top: 80px;">
      <div class="page_move" @click="movePageByDirection(false)">
        <i-chevron class="icon size_20 left"/>
      </div>

      <p v-for="(pageNationArr, index) in pageNationArr" :key="index" class="page_num"
         :class="{ 'selected' : selectedPage === pageNationArr }" @click="selectedPage = pageNationArr">
        {{ pageNationArr }}</p>

      <div class="page_move" @click="movePageByDirection(true)">
        <i-chevron class="icon size_20 right"/>
      </div>
    </div>
  </div>
</template>
