import {defineStore} from "pinia";
import {ENDPOINTS} from "@/api/constants";
import api from "@/api/api";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";

export const useStore = defineStore('transcreationInbound', () => {
    const authStore = useAuthStore();
    const router = useRouter();
    // Project list
    const projectListData = ref({}); // .result: Project list, count: Number of total projects
    const languageListDefault = ref([]);
    const translationModelListDefault = ref([]);
    const fieldListDefault = ref([]);
    const publicPromptListDefault = ref([]);
    const polishingPromptListDefault = ref([]);
    const documentTypeDefault = ref([]);

    // Admin
    const langList = ref([]);
    const translationModelList = ref([]);
    const fieldList = ref([]);
    const promptList = ref([]);
    const polishingPromptList = ref([]);
    const userList = ref([]);

    // Authentication
    const myData = ref({
        role: sessionStorage.getItem('auth'),
        name: sessionStorage.getItem('username'),
        nickname: sessionStorage.getItem('nickname'),
    });

    // Throttling
    let throttle = false;
    let throttleTimer = null;

    function setThrottling(time) {
        if (!throttle) {
            throttle = true;

            throttleTimer = setTimeout(() => {
                throttle = false;
            }, time);
        }
    }

    function endThrottling() {
        clearTimeout(throttleTimer);
        throttleTimer = null;
    }

    // General functions
    // yyyy-mm-dd
    function modifyDateToYMD(dateVal) {
        const temp = dateVal.split('T');
        return temp[0];
    }

    // Put 0 next to the number which is less than 10
    function modifyIndex(index) {
        if (index < 10) {
            return `0${index}`;
        }

        return index;
    }

    function setTamseClassName(tamseCode) {
        if (tamseCode === undefined) {
            return '';
        }
        const temp = tamseCode.split('-');
        return `${temp[0].toLowerCase()}${temp[1]}`
    }

    // Handle Api errors
    async function handleApiError(error) {
        endThrottling();

        if(error.code === "ERR_NETWORK") {
            await authStore.refreshToken();
            return
        }

        switch (error.response.status) {
            case 400:
                if (error.response.data.detail === 'User already registered') {
                    alert("이미 등록된 유저 정보가 있습니다.");
                }
                break;
            case 401:
                await authStore.logout();
                break;
            default:
                await authStore.refreshToken();
                break;
        }
    }

    // APIs
    // PROJECT
    async function getProjectList(page = 1, limit = 200) {
        try {
            const setQuery = `${ENDPOINTS.GET_PROJECT_LIST}?limit_int=${limit}&page_number=${page}`;
            const res = await api.get(setQuery);
            if (res.data) {
                projectListData.value = res.data;
                return res.data;
            }
        } catch (e) {
            console.error("get project list error", e);
            await handleApiError(e);
        }
    }

    async function searchProjectList(searchText, searchType, page = 1, limit = 10) {
        try {
            const setEndpoint = `${ENDPOINTS.SEARCH_PROJECT_LIST}?search_value=${searchText}&search_type=${searchType}&limit_int=${limit}&page_number=${page}`
            const res = await api.get(setEndpoint);

            if (res.data) {
                projectListData.value = res.data;
                return res.data;
            }

        } catch (e) {
            console.error("searchProjectList", e);
        }
    }

    async function getProjectInfo(projectId) {
        try {
            const res = await api.get(`${ENDPOINTS.GET_PROJECT_LIST}/${projectId}`);

            if (res.data) {
                return res.data.result;
            }
        } catch (e) {
            console.error("getProject info error:", e);
        }
    }

    async function getProjectTexts(projectId, type = 0) {
        try {
            const res = await api.get(ENDPOINTS.GET_PROJECT_TEXTS + projectId + `?translation_column_id=${type}`);
            if (res.data) {
                return res.data.result;
            }
        } catch (e) {
            console.error("getProjectTexts", e);
        }
    }

    async function getGlossaryData(projectId) {
        try {
            const setEndpoint = `${ENDPOINTS.GET_GLOSSARY_DATA}/${projectId}/glossary-data`;
            const res = await api.get(setEndpoint);
            if (res.data) {
                const mergedData = Object.values(
                    res.data.result.reduce((acc, item) => {
                        if (!acc[item.id]) {
                            acc[item.id] = { ...item, reference: [] };
                        }

                        if (item.reference) { // 출처 있으면 합치기
                            acc[item.id].reference.push(item.reference);
                        }
                        return acc;
                    }, {})
                );
                return mergedData;
            }
        } catch (e) {
            console.error("getGlossaryData error", e);
        }
    }

    async function getLanguageListDefault() { // PRO only can use this.
        try {
            const res = await api.get(`${ENDPOINTS.GET_PROJECT_LANGUAGE_LIST_DEAFAULT}?limit_int=20&page_number=1`);
            if (res.data) {
                languageListDefault.value = res.data.result;
                return true;
            }
        } catch (e) {
            console.error("get language list default error: ", e);
            await handleApiError(e);
        }
    }

    async function getTranslationModelListDefault() { // PRO only can use this.
        try {
            const res = await api.get(`${ENDPOINTS.GET_TRANSLATION_MODEL_LIST_DEFAULT}?limit_int=100&page_number=1`);
            if (res.data) {
                translationModelListDefault.value = res.data.result;
                return true;
            }
        } catch (e) {
            console.error("get translation model list default error: ", e);
            await handleApiError(e);
        }

    }

    async function getFieldListDefault() {
        try {
            const res = await api.get(ENDPOINTS.GET_FIELD_LIST_DEAFAULT + '?limit_int=100&page_number=1');

            if (res.data) {
                fieldListDefault.value = res.data.result;
                return true;
            }
        } catch (e) {
            await handleApiError(e);
            console.error("get fieldList default error: ", e);
        }
    }

    async function getPublicPromptListDefault(payload) {
        try {
            const setQuery = `${ENDPOINTS.GET_PUBLIC_PROMPT_LIST}?translation_project_field_id=${payload.translationProjectFieldId}&translation_model_id=${payload.translationModelId}&tamse_code=${payload.tamseCode}&prompt_type_id=${parseInt(payload.promptTypeId)}&limit_int=20&page_number=1`;

            const res = await api.get(setQuery);

            if (res.data) {
                if(payload.promptTypeId === 1) {
                    publicPromptListDefault.value = res.data.result;
                } else if(payload.promptTypeId === 2) {
                    polishingPromptListDefault.value = res.data.result;
                }

                return true;
            }
        } catch (e) {
            console.error("get public prompt list default error: ", e);
            await handleApiError(e);
        }
    }

    async function getDocumentTypeDefault() {
        try {
            const setQuery = `${ENDPOINTS.GET_DOCUMENT_LIST}?limit_int=100&page_number=1`;
            const res = await api.get(setQuery);

            if (res.data) {
                documentTypeDefault.value = res.data.result.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                return true;
            }
        } catch (e) {
            console.error("get document type default error: ", e);
            await handleApiError(e);
        }
    }



    async function deleteProject(projectId) {
        try {
            const res = await api.delete(ENDPOINTS.DELETE_PROJECT + projectId);

            if (res.data.result) {
                return res.data.result;
            }
        } catch (e) {
            console.error("deleteProject", e);
            return false;
        }
    }

    // DOWNLOAD

    function downloadFileByResponseDataType(res, type, fileName) {
        let filename = fileName; // 기본 파일명
        const typeArr = ['_original', '_trans', '_glossary', '_comparing_trans'];
        // const extensionMap = {
        //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        //     'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
        //     'application/msword': '.doc',
        //     'application/vnd.ms-excel': '.xls',
        //     'application/vnd.ms-powerpoint': '.ppt',
        //     'application/pdf': '.pdf',
        //     'text/plain': '.txt',
        //     'application/zip': '.zip',
        //     'application/json': '.json',
        //     'application/rtf': '.rtf',
        //     'application/x-hwp': '.hwp',
        //     'text/markdown': '.md',
        // };
        // const contentType = res.headers['content-type'] || 'application/octet-stream';
        const contentDisposition = res.headers['content-disposition'];

        if (contentDisposition) {
            const match = contentDisposition.match(/filename\*?=(?:UTF-8'')?["']?([^;"']+)/i);
            if (match && match[1]) {
                filename = decodeURIComponent(match[1]); // 파일명 디코딩
            }
        } else {
            const lastDotIndex = fileName.lastIndexOf('.');
            if (lastDotIndex === -1) {
                return fileName;
            }

            const fileNameTemp = fileName.slice(0, lastDotIndex);
            const extension = '.' + fileName.slice(lastDotIndex + 1);

            if(type === 0 || type === 1) {
                filename = `${fileNameTemp}${typeArr[type]}${extension}`;
            }

            if(type === 2) {
                filename = `${fileNameTemp}${typeArr[type]}.xlsx`;
            }

            if(type === 3) {
                filename = `${fileNameTemp}${typeArr[type]}`;
            }
        }

        // Blob 데이터 처리 및 다운로드
        const blob = new Blob([res.data], { type: res.headers['content-type'] || 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // 추출한 파일명으로 설정
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Blob URL 해제 (메모리 절약)
        window.URL.revokeObjectURL(url);
    }

    async function downloadOriginalDocument(projectId, fileName) {
        try {
            const res = await api.get(ENDPOINTS.DOWNLOAD_ORIGINAL_DOCUMENT + projectId, {
                responseType: "blob",
            });
            downloadFileByResponseDataType(res, 0, fileName);

        } catch (e) {
            console.error("downloadOriginalDocument error:", e);
        }
    }

    async function downloadTranslationDocument(projectId, fileName) {
        try {
            const res = await api.get(ENDPOINTS.DOWNLOAD_TRANSLATION_DOCUMENT + projectId, {
                responseType: "blob",
            });
            downloadFileByResponseDataType(res, 1, fileName);
        } catch (e) {
            console.error("downloadTranslationDocument error:", e);
        }
    }

    async function downloadGlossaryDocument(projectId, fileName) {
        try {
            const res = await api.get(ENDPOINTS.DOWNLOAD_GLOSSARY_DOCUMENT + projectId, {
                responseType: "blob",
            });
            downloadFileByResponseDataType(res, 2, fileName);
        } catch (e) {
            console.error("downloadGlossaryDocument error:", e);
        }
    }

    async function comparingTranslationDocument(projectId, fileName) {
        try {
            const res = await api.get(ENDPOINTS.DOWNLOAD_COMPARING_TRANSLATION_DOCUMENT + projectId, {
                responseType: "blob",
            });
            downloadFileByResponseDataType(res, 3, fileName);
        } catch (e) {
            console.error("downloadGlossaryDocument error:", e);
        }
    }

    // ADMIN
    // LANGUAGE
    async function getLanguageList(searchText = '') {
        try {
            const setEndpoint = searchText === '' ? ENDPOINTS.GET_LANGUAGE_LIST : `${ENDPOINTS.GET_LANGUAGE_LIST}?search_text=${searchText}`
            const res = await api.get(setEndpoint);
            langList.value = res.data.lang_list;
        } catch (e) {
            console.error("get language list error: ", e);
            await handleApiError(e);
        }
    }

    async function createLanguage(name, parameter, countType) {
        if (throttle) {
            alert("언어 생성 중입니다.")
            return;
        }

        setThrottling(300000);

        try {
            const rb = {
                name: name,
                parameter: parameter,
                count_type: countType
            }
            const res = await api.post(ENDPOINTS.CREATE_LANGUAGE, rb);

            if (res.data) {
                endThrottling();
                return true;
            }
        } catch (e) {
            console.error("create language error: ", e);
            await handleApiError(e);
        } finally {
            await getLanguageList();
        }
    }

    async function deleteLanguage(id) {
        if (throttle) {
            alert("언어 삭제 중입니다.")
            return;
        }

        setThrottling(10000);

        try {
            const res = await api.delete(ENDPOINTS.DELETE_LANGUAGE + id);

            if (res.data) {
                alert("삭제 되었습니다.");
                endThrottling();
                return true;
            }
        } catch (e) {
            console.error("delete language error: ", e);
            await handleApiError(e);
        } finally {
            await getLanguageList();
        }
    }

    //Translation Models
    async function getTranslationModelList(searchText = '') {
        try {
            const setEndpoint = searchText === '' ? ENDPOINTS.GET_TRANSLATION_MODEL_LIST : `${ENDPOINTS.GET_TRANSLATION_MODEL_LIST}?search_text=${searchText}`
            const res = await api.get(setEndpoint);
            translationModelList.value = res.data.tmodel_list;
        } catch (e) {
            console.error("get translation model list error: ", e);
            await handleApiError(e);
        }
    }

    async function createTranslationModel(providerNameId, modelName, parameter) {
        if (throttle) {
            alert("번역 엔진 생성 중입니다.")
            return;
        }

        setThrottling(10000);

        try {
            const rb = {
                provider_name_id: providerNameId,
                model_name: modelName,
                parameter: parameter
            }

            const res = await api.post(ENDPOINTS.CREATE_TRANSLATION_MODEL, rb);

            if (res.data) {
                endThrottling();
                await getTranslationModelList();
                return true;
            }
        } catch (e) {
            console.error("create translation model error: ", e);
            await handleApiError(e);
        } finally {
            await getLanguageList();
        }
    }

    async function updateTranslationModel(payload) {
        try {
            const rb = {
                provider_name_id: payload.providerNameId,
                model_name: payload.modelName,
                parameter: payload.parameter,
            }
            const res = await api.patch(ENDPOINTS.UPDATE_TRANSLATION_MODEL + payload.translationModelId, rb);
            if (res.data) {
                await getTranslationModelList();
                alert("번역 엔진이 수정 되었습니다.");
                return true;
            }
        } catch (e) {
            console.error("get translation model list error: ", e);
            await handleApiError(e);
        }
    }

    async function deleteTranslationModel(id) {
        if (throttle) {
            alert("번역 엔진 모델 삭제 중입니다.")
            return;
        }

        setThrottling(10000);

        try {
            const res = await api.delete(ENDPOINTS.DELETE_TRANSLATION_MODEL + id);

            if (res.data) {
                alert("번역 엔진 모델이 삭제 되었습니다.");
                endThrottling();
                return true;
            }
        } catch (e) {
            console.error("delete translation model error: ", e);
            await handleApiError(e);
        } finally {
            await getTranslationModelList();
        }
    }

    // Field
    async function getFieldList(searchText = '') {
        try {
            const setEndpoint = searchText === '' ? ENDPOINTS.GET_FIELD_LIST : `${ENDPOINTS.GET_FIELD_LIST}?search_text=${searchText}`
            const res = await api.get(setEndpoint);
            if (res.data) {
                fieldList.value = res.data.field_list;
                return true;
            }
        } catch (e) {
            console.error("get translation model list error: ", e);
            await handleApiError(e);
        }
    }

    async function createField(name, parameter) {
        if (throttle) {
            alert("분야 생성 중입니다.")
            return;
        }

        setThrottling(10000);

        try {
            const rb = {
                name: name,
                parameter: parameter,
                default_prompt: parameter
            }

            const res = await api.post(ENDPOINTS.CREATE_FIELD, rb);

            if (res.data) {
                endThrottling();
                await getFieldList();
                return true;
            }
        } catch (e) {
            console.error("create translation model error: ", e);
            await handleApiError(e);
        }
    }

    async function deleteField(id) {
        if (throttle) {
            alert("분야 삭제 중입니다.")
            return;
        }

        setThrottling(10000);

        try {
            const res = await api.delete(ENDPOINTS.DELETE_FIELD + id);

            if (res.data) {
                alert("분야가 삭제 되었습니다.");
                endThrottling();
                await getFieldList();
                return true;
            }
        } catch (e) {
            console.error("delete field error: ", e);
            await handleApiError(e);
        }
    }

    // Prompt
    async function getPromptList(searchText = '', promptTypeId=1) {
        try {
            const setEndpoint = `${ENDPOINTS.GET_PROMPT_LIST}?search_text=${searchText}&prompt_type_id=${promptTypeId}`
            const res = await api.get(setEndpoint);
            if(promptTypeId === 1) {
                promptList.value = res.data.prompt_list;
            }

            if(promptTypeId === 2) {
                polishingPromptList.value = res.data.prompt_list;
            }
        } catch (e) {
            console.error("get prompt list error: ", e);
            await handleApiError(e);
        }
    }

    async function createPrompt(payload) {
        // console.log("throttle", throttle)
        // if (throttle) {
        //     alert("프롬프트 생성 중입니다.")
        //     return;
        // }
        //
        // setThrottling(10000);

        try {
            const rb = {
                tamse_code: payload.tamseCode,
                translation_project_field_id: payload.translationProjectFieldId,
                prompt_type_id: payload.promptTypeId,
                translation_model_id: payload.translationModelId,
                // document_type_id: payload.documentTypeId,
                name: payload.name,
                prompt: payload.prompt,
            }

            const res = await api.post(ENDPOINTS.CREATE_PROMPT, rb);

            if (res.data) {
                endThrottling();
                await getPromptList();
                return true;
            }
        } catch (e) {
            console.error("create translation model error: ", e);
            await handleApiError(e);
        }
    }

    async function updatePromptData(rb, promptId) {
        const res = await api.patch(ENDPOINTS.UPDATE_PROMPT + promptId, rb);
        if(res.data.message === "Update prompt successfully!") {
            await getPromptList()
            return true;
        }
    }

    async function deletePrompt(id) {
        // if (throttle) {
        //     alert("프롬프트 삭제 중입니다.")
        //     return;
        // }
        //
        // setThrottling(10000);

        try {
            const res = await api.delete(ENDPOINTS.DELETE_PROMPT + id);

            if (res.data) {
                alert("프롬프트가 삭제 되었습니다.");
                endThrottling();
                await getPromptList();
                return true;
            }
        } catch (e) {
            console.error("delete prompt error: ", e);
            await handleApiError(e);
        }
    }

    // User
    async function getUserList(searchText = '') {
        try {
            const setEndpoint = searchText === '' ? ENDPOINTS.GET_USER_LIST : `${ENDPOINTS.GET_USER_LIST}?search_text=${searchText}`
            const res = await api.get(setEndpoint);
            userList.value = res.data.user_list;
        } catch (e) {
            console.error("get user list error: ", e);
            await handleApiError(e);
        }
    }

    async function getSingleUserData(userId, username) {
        try {

            const res = await api.get(`${ENDPOINTS.GET_SINGLE_USER_DATA}${userId}`);

            if (res.data) {
                myData.value = res.data.user;
                sessionStorage.setItem("username", myData.value.name);
                sessionStorage.setItem("auth", 'ADMIN');
                endThrottling();
            }
            await router.push({ name: 'ProjectList' });
        } catch (e) {
            console.error("get single user data error: ", e);
            sessionStorage.setItem("username", username);
            myData.value.username = username;
            sessionStorage.setItem("auth", 'PRO');
            myData.value.auth = 'PRO';
            return true;
            // await handleApiError(e);
        }
    }

    async function createUser(payload) {
        // if (throttle) {
        //     alert("프롬프트 생성 중입니다.")
        //     return;
        // }
        //
        // setThrottling(10000);

        // 필수 값
        // role, email, name, password
        try {
            const rb = {
                name: payload.name,
                role: payload.role,
                nickname: payload.nickname,
                email: payload.email,
                password: payload.password,
                bound_type: 'INBOUND'
            }

            const res = await api.post(ENDPOINTS.CREATE_USER, rb);

            if (res.data) {
                endThrottling();
                await getUserList();
                return true;
            }
        } catch (e) {
            console.error("create user error: ", e);
            await handleApiError(e);
        }
    }

    async function updateUserData(rb, userId) {
        try {
            const res = await  api.patch(ENDPOINTS.UPDATE_USER_DATA + userId, rb);
            if(res.data.message === "Update user successfully!") {
                await getUserList();
                return true;
            }
        } catch (e) {
            console.error("update userData error: ", e);
        }
    }

    async function updatePassword(id, pw) {
        try {
            const res = await api.patch(`${ENDPOINTS.UPDATE_USER_PASSWORD}${id}/change_password`, {password: pw});
            if(res.data.message === "Update user successfully!") {
                return true;
            }
        } catch (e) {
            console.error("update password error: ", e);
        }
    }

    async function deleteUser(id) {
        // if (throttle) {
        //     alert("프롬프트 삭제 중입니다.")
        //     return;
        // }
        //
        // setThrottling(10000);

        try {
            const res = await api.delete(ENDPOINTS.DELETE_USER + id);

            if (res.data) {
                alert("유저가 삭제 되었습니다.");
                endThrottling();
                await getUserList();
                return true;
            }
        } catch (e) {
            console.error("delete User error: ", e);
            await handleApiError(e);
        }
    }

    return {
        // Variables
        throttle,
        throttleTimer,
        // Project
        projectListData,
        languageListDefault,
        translationModelListDefault,
        fieldListDefault,
        publicPromptListDefault,
        polishingPromptListDefault,
        documentTypeDefault,

        // Admin
        langList,
        translationModelList,
        fieldList,
        promptList,
        polishingPromptList,
        userList,

        // Authentication
        myData,

        // Functions
        modifyDateToYMD,
        modifyIndex,
        setTamseClassName,
        setThrottling,
        endThrottling,

        // APIs
        // Project
        getProjectList,
        getProjectInfo,
        getProjectTexts,
        getGlossaryData,
        deleteProject,

        // ADMIN 과 PRO가 둘 다 쓰는 것은 접미사 Default
        searchProjectList,
        getLanguageListDefault,
        getTranslationModelListDefault,
        getFieldListDefault,
        getPublicPromptListDefault,
        getDocumentTypeDefault,

        // Download
        downloadOriginalDocument,
        downloadGlossaryDocument,
        downloadTranslationDocument,
        comparingTranslationDocument,

        // Admin
        // Language
        getLanguageList,
        createLanguage,
        deleteLanguage,

        // Translation Model
        getTranslationModelList,
        createTranslationModel,
        updateTranslationModel,
        deleteTranslationModel,

        // Field
        getFieldList,
        createField,
        deleteField,

        // Prompt
        getPromptList,
        createPrompt,
        updatePromptData,
        deletePrompt,

        // User
        getUserList,
        getSingleUserData,
        createUser,
        updateUserData,
        updatePassword,
        deleteUser,

        // Error handling
        handleApiError,

    }
})
