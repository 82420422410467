import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ENDPOINTS } from "@/api/constants";
import api from "@/api/api";
import { useStore } from "@/store/index";

export const useAuthStore = defineStore("auth", () => {
    const store = useStore();
    const router = useRouter();
    const userToken = ref({
        accessToken: sessionStorage.getItem('accessToken') || '',
        refreshToken: sessionStorage.getItem('refreshToken') || '',
    });
    const userId = ref(Number(sessionStorage.getItem("userId")) || 0);
    const isAuthenticated = ref(!!(sessionStorage.getItem('accessToken') && sessionStorage.getItem('refreshToken')));

    async function login(username, password) {
        try {
            if (store.throttle) {
                alert("로그인 중입니다. 잠시만 기다려주세요.")
                return;
            }

            store.setThrottling(3000);

            const rb = {
                bound_type: 'INBOUND',
                name: username, // 등록한 아이디 입니다.
                password: password
            }

            const res = await api.post(ENDPOINTS.LOGIN, rb);

            if(res.data) {
                userToken.value.accessToken = res.data.access_token;
                userToken.value.refreshToken = res.data.refresh_token;
                userId.value = res.data.id;

                sessionStorage.setItem("accessToken", res.data.access_token);
                sessionStorage.setItem("refreshToken", res.data.refresh_token);
                sessionStorage.setItem("userId", res.data.id);
                sessionStorage.setItem("username", res.data.name);
                sessionStorage.setItem("nickname", res.data.nickname);
                sessionStorage.setItem("auth", res.data.role_id === 1 ? 'ADMIN' : 'PRO');

                isAuthenticated.value = true;

                store.myData.email = res.data.email;
                store.myData.username = res.data.name;
                store.myData.nickname = res.data.nickname;
                store.myData.role = res.data.role_id === 1 ? 'ADMIN' : 'PRO';

                store.endThrottling();
                await router.push({ name: 'ProjectList' });
            }
        } catch (error) {
            console.error("Login error:", error);
            store.endThrottling();
        }
    }

    async function logout() {
        try {
            userToken.value.accessToken = "";
            userToken.value.refreshToken = "";
            userId.value = "";
            store.myData.email = '';
            store.myData.username = '';
            store.myData.nickname = '';
            store.myData.role = '';
            isAuthenticated.value = false;

            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem("auth");
            sessionStorage.removeItem("nickname");
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem("username");

            console.log('토큰이 만료되었습니다. 다시 로그인 해주세요.')
            await router.push({ name: 'Login' });
        } catch (error) {
            console.error("logout error:", error);
        }

    }

    async function refreshToken() {
        try {
            const rb = {
                refresh_token: userToken.value.refreshToken
            }

            const res = await api.post(ENDPOINTS.REFRESH_TOKEN, rb);

            if(res.data) {
                userToken.value.accessToken = res.data.access_token;
                sessionStorage.removeItem("accessToken");
                sessionStorage.setItem("accessToken", res.data.access_token);
                return true;
            }
        } catch (error) {
            console.error("Token refresh error:", error);
            await logout();
        }
    }

    return {
        userToken,
        isAuthenticated,
        userId,

        login,
        logout,
        refreshToken,
    };
});
