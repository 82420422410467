import axios from "axios";
import { useAuthStore } from "@/store/auth";
import {ENDPOINTS} from "@/api/constants";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT
});

const calculateExpireTimeStamp = (token) => { // jwt payload 뽑아서 디코딩 후, 토큰 만료 기간과 현재 시간과의 차 1분 이하인지 계산하여 반환
    const payload = token.split('.')[1];
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/'); // Base64 변환
    const decodedPayload = JSON.parse(atob(base64)); // Base64 디코딩 후 JSON 파싱
    const currentTimeStamp = Math.floor(Date.now() / 1000); // 현재 시간
    const isLessThanOneMinute = decodedPayload.exp - currentTimeStamp; // 시간 차

    return isLessThanOneMinute <= 60; // 1분 이하냐?
}

const setAuthHeader = async (config) => {
    if (config.url === ENDPOINTS.LOGIN || config.url === ENDPOINTS.REFRESH_TOKEN) {
        return config;
    }

    const authStore = useAuthStore();
    let token = authStore.userToken.accessToken;

    if (!calculateExpireTimeStamp(token)) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    } else {
        const isRefreshed = await authStore.refreshToken();

        if(isRefreshed) {
            token = authStore.userToken.accessToken;
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        }
    }
};

api.interceptors.request.use(setAuthHeader);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const authStore = useAuthStore();

        if (error.response && error.response.status === 401 && authStore.userToken.refreshToken) {
            try {
                const isRefreshed = await authStore.refreshToken();

                if(isRefreshed) {
                    error.config.headers.Authorization = `Bearer ${authStore.userToken.accessToken}`;
                    return api.request(error.config);
                }
            } catch (refreshError) {
                await authStore.logout();
            }
        }

        return Promise.reject(error);
    }
);

export default api;
