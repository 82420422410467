<script>
export default {
  name: "i-paraphrase",
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/Variant15" clip-path="url(#clip0_12018_1147)">
      <g id="Group">
        <path id="Vector"
              d="M13.168 6.14616L13.168 2.47949C13.168 1.78914 12.6083 1.22949 11.918 1.22949L4.08463 1.22949C3.39428 1.22949 2.83463 1.78914 2.83463 2.47949L2.83463 6.14616C2.83463 6.83651 3.39428 7.39616 4.08463 7.39616L11.918 7.39616C12.6083 7.39616 13.168 6.83651 13.168 6.14616Z"
              fill="currentColor"/>
        <path id="Vector_2"
              d="M2.83463 17.8132L2.83463 14.1465C2.83463 13.4548 3.39297 12.8965 4.08463 12.8965L11.918 12.8965C12.6096 12.8965 13.168 13.4548 13.168 14.1465L13.168 17.8132C13.168 18.5048 12.6096 19.0632 11.918 19.0632L4.08463 19.0632C3.39297 19.0631 2.83463 18.5048 2.83463 17.8132ZM12.0013 14.1465C12.0013 14.1048 11.968 14.0632 11.918 14.0632L4.08463 14.0632C4.04297 14.0632 4.0013 14.0965 4.0013 14.1465L4.0013 17.8132C4.0013 17.8548 4.03463 17.8965 4.08463 17.8965L11.918 17.8965C11.968 17.8965 12.0013 17.8632 12.0013 17.8132L12.0013 14.1465Z"
              fill="currentColor"/>
        <path id="Vector_3"
              d="M11.543 10.1465C11.543 9.80482 11.8263 9.52148 12.168 9.52148L13.418 9.52148C13.7596 9.52148 14.043 9.80482 14.043 10.1465C14.043 10.4882 13.7596 10.7715 13.418 10.7715L12.168 10.7715C11.8263 10.7715 11.543 10.4882 11.543 10.1465Z"
              fill="currentColor"/>
        <path id="Vector_4"
              d="M8.20996 10.1465C8.20996 9.80482 8.49329 9.52148 8.83496 9.52148L10.085 9.52148C10.4266 9.52148 10.71 9.80482 10.71 10.1465C10.71 10.4882 10.4266 10.7715 10.085 10.7715L8.83496 10.7715C8.49329 10.7715 8.20996 10.4882 8.20996 10.1465Z"
              fill="currentColor"/>
        <path id="Vector_5"
              d="M4.87598 10.1465C4.87598 9.80482 5.15931 9.52148 5.50098 9.52148L6.75098 9.52148C7.09264 9.52148 7.37598 9.80482 7.37598 10.1465C7.37598 10.4882 7.09264 10.7715 6.75098 10.7715L5.50098 10.7715C5.15931 10.7715 4.87598 10.4882 4.87598 10.1465Z"
              fill="currentColor"/>
        <path id="Vector_6"
              d="M1.54297 10.1465C1.54297 9.80482 1.8263 9.52148 2.16797 9.52148L3.41797 9.52148C3.75964 9.52148 4.04297 9.80482 4.04297 10.1465C4.04297 10.4882 3.75964 10.7715 3.41797 10.7715L2.16797 10.7715C1.8263 10.7715 1.54297 10.4882 1.54297 10.1465Z"
              fill="currentColor"/>
        <path id="Vector_7"
              d="M15.5848 5.07962C15.7181 5.49629 15.8514 5.92129 15.9764 6.33796C16.0681 6.65462 15.9181 6.90462 15.6014 6.97962C15.3931 7.02962 15.2014 6.93796 15.1014 6.75462C15.0598 6.67129 15.0264 6.58796 15.0014 6.49629C14.7681 5.78796 14.5431 5.08796 14.3181 4.37962C14.2098 4.05462 14.3431 3.77129 14.6681 3.67129C15.4098 3.42962 16.1514 3.19629 16.9014 2.97129C17.0181 2.93796 17.1681 2.93796 17.2848 2.97129C17.5014 3.03796 17.6264 3.20462 17.6181 3.43796C17.6181 3.67129 17.4764 3.82129 17.2598 3.89629C16.9514 4.00462 16.6348 4.09629 16.2681 4.21296C16.5431 4.38796 16.7764 4.53796 17.0014 4.69629C18.7931 5.95462 19.7931 7.67962 19.9764 9.86296C20.0848 11.163 19.8181 12.4046 19.2098 13.5546C18.3348 15.2046 16.9931 16.3213 15.2181 16.913C14.8598 17.0296 14.5598 16.8296 14.5431 16.463C14.5348 16.238 14.6514 16.0963 14.8514 16.0046C15.2764 15.8213 15.7264 15.663 16.1181 15.4213C17.8848 14.3296 18.8598 12.738 18.9931 10.6463C19.0681 9.49629 18.7931 8.43796 18.2348 7.44629C17.6764 6.44629 16.8764 5.69629 15.8764 5.15462C15.8014 5.11296 15.7098 5.07962 15.6348 5.04629C15.6181 5.06296 15.6014 5.07129 15.5848 5.08796L15.5848 5.07962Z"
              fill="currentColor"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_12018_1147">
        <rect width="20" height="20" fill="transparent"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped>

</style>
